import React, { useEffect } from "react";
import Footer from "./footer";
import Header from "./header";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section id="about" class="about-area ptb-100">
        <div class="container">
          <div class="section-title">
            <h2>About Our App</h2>
            <div class="bar"></div>
            <p>
              Welcome to the TAPP ME System, where innovation meets efficiency
              in the construction industry.
            </p>
          </div>

          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-content">
                <h3>Our process is simple, Our App is powerful</h3>

                <p class="text-justify">
                  Our journey began with a simple insight: amidst the vast array
                  of tasks and processes in construction, there was an untapped
                  potential for digitization. Traditional methods often led to
                  inefficiency, disjointed communication, and unnecessary
                  complexity. Our passionate team of architects recognized this
                  gap and set out on a mission to revolutionize how construction
                  projects are managed.
                </p>
                <p class="text-justify">
                  {" "}
                  Our vision is to simplify and empower the work process, we
                  created a comprehensive solution that would streamline
                  operations for all sides - employees, supervisors and
                  managers. Thus was born TAPP ME – a necessary instrument
                  designed to centralize essential functions and facilitate
                  collaboration, all accessible from the palm of your hand.
                </p>
                <p class="text-justify">
                  From inception to development, every aspect of TAPP ME has
                  been carefully designed to meet the diverse needs of the
                  construction industry. Countless hours of analysis,
                  brainstorming and design refinement have created a platform
                  that redefines task management, communication and project
                  monitoring.
                </p>
                <p class="text-justify">
                  At TAPP ME, our mission is clear: to empower individuals and
                  teams in the construction industry by providing them with a
                  powerful tool that simplifies their workflows and increases
                  productivity. Whether you're on location, in the office or on
                  the go, TAPP ME ensures that everything you need is easily
                  accessible at your fingertips.
                </p>
                <p>
                  Follow us and get on board the construction management of the
                  future. Do the work convenient, efficient and innovative of
                  TAPP ME, and discover a new standard of excellence in project
                  execution.
                </p>

                <div class="about-btn">
                  <button
                    class="default-btn"
                    onClick={() => {
                      document
                        .getElementById("footerDownloadLink")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Download Now
                    <span></span>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="about-image">
                <img src="assets/img/tapme-mobile.png" alt="image" />
              </div>
            </div>
          </div>
        </div>

        <div class="default-shape">
          <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
          </div>

          <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
          </div>

          <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
          </div>

          <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
          </div>

          <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
          </div>
        </div>
      </section>

      <section id="features" class="features-area pb-70">
        <div class="container">
          <div class="section-title">
            <h2>Geofencing Check/Check-Out</h2>
            <div class="bar"></div>
            <p>
              This feature ensures accurate location tracking by creating
              virtual boundaries. Workers receive automatic notifications when
              they enter or leave a geofenced area, enhancing security and
              accountability.
            </p>
          </div>

          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-sun"></i>
                </div>
                <h3>Workflow Management</h3>
                <p>
                  Tapp Me streamlines task tracking, resource allocation, and
                  project timelines, ensuring that tasks are managed efficiently
                  and completed on time.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-lightbulb"></i>
                </div>
                <h3>Electronic Reporting</h3>
                <p>
                  Workers can generate reports directly from the field using
                  photos, videos, and text descriptions. These reports are
                  securely stored in the cloud and can be generated
                  automatically or manually.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-desktop"></i>
                </div>
                <h3>Web-based Admin Panel</h3>
                <p>
                  A comprehensive admin panel accessible via web browsers allows
                  for easy management of subscriptions, CRM data, and time-log
                  reports. It also provides tools for managing geofencing and
                  worker activity.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-code"></i>
                </div>
                <h3>Cross-Platform Accessibility</h3>
                <p>
                  The app is available on Android, iOS, and web platforms,
                  ensuring that it can be used across different devices and
                  environments.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-eye"></i>
                </div>
                <h3>Security and Compliance</h3>
                <p>
                  Tapp Me ensures that all data is securely stored in compliance
                  with GDPR, with robust admin controls to manage access and
                  maintain privacy.
                </p>
              </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="single-features">
                <div class="icon">
                  <i class="fa fa-camera"></i>
                </div>
                <h3>Unlimited Features</h3>
                <p>
                  Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In
                  sagittis eg esta ante, sed viverra nunc tinci dunt nec elei
                  fend et tiram.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="default-shape">
          <div class="shape-1">
            <img src="assets/img/shape/1.png" alt="image" />
          </div>

          <div class="shape-2 rotateme">
            <img src="assets/img/shape/2.png" alt="image" />
          </div>

          <div class="shape-3">
            <img src="assets/img/shape/3.svg" alt="image" />
          </div>

          <div class="shape-4">
            <img src="assets/img/shape/4.svg" alt="image" />
          </div>

          <div class="shape-5">
            <img src="assets/img/shape/5.png" alt="image" />
          </div>
        </div>
      </section>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div class="logog p-4 pt-lg-5">
                    <img src="assets/img/tappme-logo.png" />
                    <p class="mt-4 mb-3 text-color">
                      Try TAPPME Startup/ Professional Plans
                    </p>
                    <h4 class="fw-normal mt-4">15 days Free</h4>
                    <p class="mt-3 mb-3 text-color">
                      After free trial $475/year will be charged
                    </p>
                    <div class="border py-4 px-3">
                      <p class="d-flex justify-content-between align-items-center text-dark mb-3">
                        TAPPME Startup/Professional Plans
                        <span class=" text-dark">14 days free</span>
                      </p>
                      <p class="d-flex justify-content-between align-items-center text-black-50">
                        Quantity: 10{" "}
                        <span class="text-black-50">$475/year</span>
                      </p>
                      <hr />
                      <p class="d-flex justify-content-between align-items-center text-gray mb-3">
                        Subtotal <span class="">$475</span>
                      </p>

                      <p class="d-flex justify-content-between align-items-center text-primary mt-3">
                        Add promotion code{" "}
                        <span class="w-50">
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                  style={{
                    boxShadow: "0px 4px 22.2px 0px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <div class="logog p-4 px-lg-5 pt-lg-5">
                    <h4 class="fw-normal">Enter payment details</h4>

                    <div class="mb-3 mt-5">
                      <label class="form-label">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        id="exampleFormControlInput1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Understood
              </button>
            </div>
          </div>
        </div>
      </div>
      <section id="footerDownloadLink">
        <Footer />
      </section>
    </>
  );
};

export default AboutUs;
